import { UploadTaskBase } from './UploadTaskBase';

/** @import { FragmentList } from "../../FragmentList" */

export class RemainingFragmentsTask extends UploadTaskBase {

    /**
     * Creates a new task to upload the remaining fragments of a mesh
     *
     * @param {OutOfCoreTileManager} outOfCoreTileManager - The OutOfCoreTileManager instance
     * @param {FragmentList} fragList - The fragment list
     * @param {number} bvhNodeId - The BVH node ID
     * @param {number[]} remainingFragmentIndices - The indices of the remaining fragments
     */
    constructor(outOfCoreTileManager, fragList, bvhNodeId, remainingFragmentIndices) {
        super(outOfCoreTileManager, fragList, bvhNodeId);
        this.remainingFragmentIndices = remainingFragmentIndices;
    }

        /**
     * Returns the geometries to be uploaded
     * @returns {THREE.BufferGeometry[]} The geometries to be uploaded
     */
    getGeometries() {
        return this.remainingFragmentIndices.map((index) => this.fragList.getGeometry(index));
    }
}
