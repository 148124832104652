import * as EventTypes from "../../../application/EventTypes";
import { MODEL_CONSOLIDATION_ENABLED } from '../../../application/PrivateEventTypes';
import { BvhNode } from './BvhNode';
import { ConsolidationTask } from './tasks/ConsolidationTask';
import { RemainingFragmentsTask } from './tasks/RemainingFragmentsTask';
import { InstancedMeshUploadTask } from './tasks/InstancedMeshUploadTask';
import { NO_MESH_FOR_FRAGMENT } from '../consolidation/Consolidation';
import { applyInstancingToRange } from "../consolidation/FragmentListConsolidation";
import { ModelIteratorBVH } from '../ModelIteratorBVH';
import { CallbackTask } from "./tasks/CallbackTask";
import { GPU_MEMORY_LIMIT } from "../../globals";
import { analytics } from "../../../analytics/index";

/** @import { RenderModel } from "../RenderModel" */
/** @import { Viewer3DImpl } from "../../../application/Viewer3DImpl" */
/** @import { ModelIteratorBVH } from "../ModelIteratorBVH" */
/** @import { WebGLRenderer } from "../../render/WebGLRenderer" */

/**
 * Class responsible for managing the memory for BVH tiles
 *
 * @alias Autodesk.Viewing.Private.OutOfCoreTileManager
 * @private
 */
export class OutOfCoreTileManager {
    // Global data structures that are shared across all viewer instances and models
    static #memoryLimit = 0;
    static #memoryConsumed = 0;
    static #stats = {
        uploadedThisFrame: 0,
        removedThisFrame: 0,
        uploadedLastSecond: 0,
        removedLastSecond: 0,
        uploadedThisSecond: 0,
        removedThisSecond: 0
    };

    /** @type {BvhNode[]} */
    static #bvhNodesPendingQueue = [];

    /** @type {BvhNode[]} */
    static #bvhNodesWorkingSetOnGpu = [];

    static #statsUpdateTimer;

    /** @type {Set<OutOfCoreTileManager>} Out of core tile managers that have not yet received a viewer.addModel event */
    static notYetAddedManagers = new Set();

    /** @type {Array<BvhNode>} BVHNodes that are still waiting for geometries from the server */
    static pendingNodes = [];

    /** @type {Map<LeanBufferGeometry, number>} */
    static geomRefCounts = new Map();

    /** @type {Map<Model|string, OutOfCoreTileManager} */
    static modelManagerRegistry = new Map();

    /** @type {Function[]} */
    static globalTasks = [];

    /** @type {Function[]} */
    static recurringTasks = [];

    static onlyGlobalTasksExecuted = false;

    // Instance data structures that are specific to a model
    #frameCounts = [];

    /** @type {RenderModel} */
    model;

    /** @type {Array<BvhNode>} can be sparse! */
    bvhNodes = [];

    /** @type {Map<number, Set<number>>} */
    iteratorLockedTiles = new Map();

    /** @type {Viewer3DImpl[]} */
    #viewers = [];

    /** @type {WebGLRenderer} */
    #renderer;

    /** @type {ModelIteratorBVH[]} */
    iteratorIdRegistry = [];

    /** @type {Number} - start time stamp when model was created */
    static #t0_ModelCreation = undefined;

    /** @type {Number} - end time stamp for GPU upload reaching the memory limit if so */
    static #t1_GPUUpload = undefined;

    /** @type {Number} - Global counter of resource independent tasks. This is an optimization to prevent unnecessary iterations. Increasing and decreasing is done by the bvh nodes. */
    static numResourceIndependentTasks = 0;

    /**
     * Creates a new OutOfCoreTileManager
     * @param {RenderModel} model - The model for which this tile manager is responsible
     */
    constructor(model) {
        this.model = model;
        OutOfCoreTileManager.setMemoryLimit(GPU_MEMORY_LIMIT);
    }

    /**
     * Returns the BVH node for the given node index or returns a new one if none existed
     *
     * @param {number} nodeIdx
     * @returns {BvhNode}
     */
    getBvhNode(nodeIdx) {
        let node = this.bvhNodes[nodeIdx];
        if (!node) {
            node = new BvhNode(nodeIdx, this.model, this);
            this.bvhNodes[nodeIdx] = node;
        }

        return node;
    }

    /**
     * Checks, whether the given BVH node has already been fully initialized
     * @param {number} bvhNodeId
     * @returns {boolean}
     */
    bvhNodeInitialized(bvhNodeId) {
        return this.bvhNodes[bvhNodeId]?.initialized;
    }

    /**
     * Adds a task for the given meshIndex to the OutOfCoreTileManager.
     * Depending on the type of the meshIndex, it will either create a ConsolidationTask or an InstancedMeshUploadTask.
     *
     * @param {number} bvhNodeId - The ID of the BVH node.
     * @param {number} meshIndex - Index of consolidated/instanced mesh
     * @param {FragmentList} fragList - Fragment list for the model
     * @returns {null|undefined} Returns null if consolidation is not available, undefined otherwise.
     */
    addTask(bvhNodeId, meshIndex, fragList) {
        const consolidation = this.model.getConsolidation();

        if (!consolidation) {
                return null;
        }

        let bvhNode = this.getBvhNode(bvhNodeId);
        // Node already consolidated?
        if (!consolidation.meshGeometryAvailable(meshIndex)) {
            // Did we already create a task for this meshIndex?
            if (!bvhNode.hasTask(meshIndex)) {
                let newTask = new ConsolidationTask(
                        this,
                        meshIndex,
                        fragList,
                        bvhNodeId
                );

                bvhNode.addTask(newTask);

                this.#addBvhNodeToPendingQueue(bvhNode);
            }
        }

        let geometry = consolidation.meshes[meshIndex].geometry;
        // should the geometry be rendered via hardware instancing?
        if (geometry.numInstances > 1) {
            // Is there already a InstancedMeshUploadTask for this meshIndex?
            let instancedMeshUploadTask = bvhNode.getInstancedMeshUploadTask();

            // If not create the task and add it to the node
            if (!instancedMeshUploadTask) {
                instancedMeshUploadTask = new InstancedMeshUploadTask(
                    this,
                    meshIndex,
                    fragList,
                    bvhNodeId
                );

                bvhNode.addTask(instancedMeshUploadTask);

                this.#addBvhNodeToPendingQueue(bvhNode);
            } else {
                // Otherwise add the mesh to the existing task
                instancedMeshUploadTask.addGeometry(meshIndex);
            }
        }
    }

    /**
     * Adds a task that executes a generic callback
     * @param {number} nodeIdx - The index of the BVH node
     * @param {function} callback - The callback to be executed by this task
     * @param {boolean} isOneShot - Whether this task is removed from the queue after finishing
     */
    addCallbackTask(nodeIdx, callback, isOneShot = false, isResourceIndependent = false) {
        const bvhNode = this.getBvhNode(nodeIdx);

        const task = new CallbackTask(this, callback);
        task.isOneShot = isOneShot;
        task.isResourceIndependent = isResourceIndependent;
        bvhNode.addTask(task);

        this.#addBvhNodeToPendingQueue(bvhNode);
    }


    /**
     * Adds the geometry for this scene to pendingNodes array to be requested via the loader.
     *
     * @param {number} bvhNodeId - The ID of the node
     */
    requestGeometryForNode(bvhNodeId) {
        const bvhModelIterator = this.model.getIterator();
        const renderBatch = bvhModelIterator.getGeomScenes()[bvhNodeId];
        if (!renderBatch) { // empty node. Expected e.g. for inner nodes in the transparent tree.
            return false;
        }

        const node = this.getBvhNode(bvhNodeId);
        OutOfCoreTileManager.pendingNodes.push(node);
    }

    /**
     * Creates all tasks that are needed to process the node. A node can only be initialized if all required
     * geometries are available. Otherwise, the initialization will fail.
     *
     * @param {number} bvhNodeId - The ID of the BVH node
     */
    initializeNode(bvhNodeId) {
        if (this.bvhNodeInitialized(bvhNodeId)) {
            console.warn("Node already initialized");
            return;
        }

        const consolidation = this.model.getConsolidation();
        const fragList = this.model.getFragmentList();
        const bvhModelIterator = this.model.getIterator();
        let node = this.getBvhNode(bvhNodeId);

        if (!(bvhModelIterator instanceof ModelIteratorBVH)) {
            console.error("ModelIterator is not a BVH iterator");
            return;
        }

        const renderBatch = bvhModelIterator.getGeomScenes()[bvhNodeId];
        if (!renderBatch) {
            console.error("No render batch for node");
            return;
        }

        // these are only set when early consolidation is used
        consolidation.consolidationMap.bvhNodeToRanges[bvhNodeId]?.forEach(rangeIndex => {
            // There's this.#getViewer(), but it returns undefined if a model is loaded with `loadAsHidden: true` because no MODEL_ADDED_EVENT is fired.
            const matman = this.model.loader.viewer3DImpl.matman();
            consolidation.consolidationMap._buildConsolidationMesh(rangeIndex, fragList, matman, this.model, true, false, consolidation);
        });
        consolidation.consolidationMap.bvhNodeToInstancingRanges[bvhNodeId]?.forEach(([rangeBegin, rangeEnd]) => {
            // don't call applyInstancingToRange multiple times if the range spans multiple nodes
            if (consolidation.fragId2MeshIndex[consolidation.consolidationMap.fragOrder[rangeBegin]] !== -1      ) {
                return;
            }
            const matman = this.model.loader.viewer3DImpl.matman();
            applyInstancingToRange(this.model, matman, consolidation.consolidationMap.fragOrder, rangeBegin, rangeEnd, consolidation);
        });

        let renderBatchIndices = renderBatch.getIndices();

        // Initialize the node
        node.transparent = bvhModelIterator.isNodeTransparent(bvhNodeId);
        node.initialized = true;

        // Add tasks for all meshes in the render batch
        for (let i = renderBatch.start; i < renderBatch.lastItem; i++) {
            let fragId = renderBatchIndices[i];
            let meshIndex = consolidation.fragId2MeshIndex[fragId];
            if (meshIndex === NO_MESH_FOR_FRAGMENT) {
                continue;
            }
            if (!consolidation.meshes[meshIndex].geometry) {
                continue;
            }
            if (node.transparent) { // transparent nodes cannot be consolidated because we need to sort individual fragments by depth
                continue;
            }

            this.addTask(bvhNodeId, meshIndex, fragList);
        }

        // Add tasks for all remaining fragments
        const indices = consolidation.nodeId2SingleFragIds && consolidation.nodeId2SingleFragIds[bvhNodeId];
        if (indices && indices.length > 0) {
            this.setRemainingFragmentsForNode(bvhNodeId, fragList, indices);
        }
    }

    /**
     * Releases an iterator, if it is no longer used (when the consolidation iterator that
     * references it is being destroyed or the model is removed from the viewer)
     * @param {number} iteratorId
     */
    releaseIterator(iteratorId) {
        this.resetScreenSpaceErrors(iteratorId);
        this.resetLockedTiles(iteratorId);
        this.iteratorIdRegistry[iteratorId] = undefined;

        if (this.iteratorIdRegistry.every(id => id === undefined)) {
            this.freeAllNodes();
        }
    }

    /**
     * Reactivate the iterator at the given iterator ID (when a consolidation iterator is being recreated)
     * @param {number} iteratorId
     * @param {ModelIteratorBVH} iterator
     */
    activateIterator(iteratorId, iterator) {
        this.iteratorIdRegistry[iteratorId] = iterator;
    }

    /**
     * Frees the memory for all nodes in the OutOfCoreTileManager
     * @param {number} iteratorId - The ID of the iterator
     */
    freeAllNodes() {
        this.bvhNodes.forEach(node => { // forEach skips empty slots
            // Free memory used by the node
            let freedMemory = node.freeMemory();
            OutOfCoreTileManager.#memoryConsumed -= freedMemory;
            node.remainingTasks.splice(0, node.remainingTasks.length);
            node.initialized = false;
        });

        // Remove the node from the working set and the consolidation queue
        OutOfCoreTileManager.#bvhNodesWorkingSetOnGpu = OutOfCoreTileManager.#bvhNodesWorkingSetOnGpu.filter(node => node.outOfCoreTileManager !== this);
        OutOfCoreTileManager.#bvhNodesPendingQueue = OutOfCoreTileManager.#bvhNodesPendingQueue.filter(node => node.outOfCoreTileManager !== this);
    }

    /**
     * Retrieves the consolidation mesh for a given iterator ID, BVH node ID, mesh index, fragment list, draw mode, and special handling.
     * @param {number} iteratorId - The iterator ID.
     * @param {number} bvhNodeId - The BVH node ID.
     * @param {number} meshIndex - Index of consolidate/instanced mesh
     * @param {FragmentList} fragList - Fragment list for the model
     * @param {number} drawMode - Render pass id from RenderFlags.
     * @param {Bool} specialHandling - True if the mesh needs special handling
     *
     * @returns {THREE.Mesh|null} The consolidation mesh if available, otherwise null.
     */
    getConsolidationMesh(iteratorId, bvhNodeId, meshIndex, fragList, drawMode, specialHandling) {
        const consolidation = this.model.getConsolidation();

        if (!consolidation) {
                return null;
        }

        let bvhNode = this.getBvhNode(bvhNodeId);
        bvhNode.updateLastRendered(iteratorId, this.getFrameCount(iteratorId));

        // Check if there is already a consolidated mesh for this meshIndex
        if (!consolidation.meshGeometryAvailable(meshIndex)) {
            // Return null to indicate that the mesh is not yet consolidated
            return null;
        }

        let geometry = consolidation.meshes[meshIndex].geometry;
        // If the mesh is instanced, we need to make sure that the instanced geometry has been uploaded
        if (geometry.numInstances > 1 && geometry.streamingDraw === true) {
            return null;
        }

        // If there is a consolidated mesh, apply the attributes and return it
        return consolidation.applyAttributes(meshIndex, fragList, drawMode, specialHandling);
    }

    /**
     * Updates the cost and transparency flag of a BVH node.
     *
     * @param {number} iteratorId - The ID of the iterator.
     * @param {number} bvhNodeId - The ID of the BVH node.
     * @param {number} cost - The cost to update the BVH node with.
     */
    updateBvhNodeScreenSpaceError(iteratorId, bvhNodeId, cost) {
        let node = this.getBvhNode(bvhNodeId);

        node.updateScreenSpaceError(iteratorId, cost, this.getFrameCount(iteratorId));
    }

    /**
     * Sets the remaining fragments for a given BVH node.
     *
     * @param {number} bvhNodeId - The ID of the BVH node.
     * @param {FragmentList} fragList - Fragment list for the model.
     * @param {number[]} fragmentIds - The IDs of the remaining fragments.
     */
    setRemainingFragmentsForNode(bvhNodeId, fragList, fragmentIds) {
        let bvhNode = this.getBvhNode(bvhNodeId);

        const remainingFragmentsTask = bvhNode.getRemainingFragmentsTask();
        if (!remainingFragmentsTask) {
            // Split fragments up into multiple tasks to allow smaller uploads per frame.
            const fragsPerTask = 100;
            for (let i = 0; i < fragmentIds.length; i += fragsPerTask) {
                bvhNode.addTask(new RemainingFragmentsTask(this, fragList, bvhNodeId, fragmentIds.slice(i, i + fragsPerTask)));
            }

            this.#addBvhNodeToPendingQueue(bvhNode);
        } else {
            // This function should never be invoked twice for the same node and thus
            // there should never be a RemainingFragmentsTask already present
            throw new Error("setRemainingFragmentsForNode invoked twice");
        }
    }

    #addBvhNodeToPendingQueue(bvhNode) {
        // When we have added the first task to the node, we add it to the consolidation task queue
        if (bvhNode.remainingTasks.length === 1 && OutOfCoreTileManager.#bvhNodesPendingQueue.indexOf(bvhNode) === -1) {
            OutOfCoreTileManager.#bvhNodesPendingQueue.push(bvhNode);
        }
    }

    /**
     * Removes the locked flag from all tiles. This should only be called,
     * once all candidate renderbatches have been cleared and therefore
     * no old consolidated mesh is any longer referenced.
     *
     * @param {number} iteratorId -- The id of the iterator
     */
    resetLockedTiles(iteratorId) {
        let lockedTiles = this.iteratorLockedTiles.get(iteratorId);
        if (lockedTiles) {
            for (let tileId of lockedTiles) {
                this.unlockTile(iteratorId, tileId);
            }
        }
    }

    /**
     * Unlocks a tile corresponding to the given nodeId
     * @param {number} iteratorId -- The id of the iterator
     * @param {number} bvhNodeId -- The id of the node in the BVH
     */
    unlockTile(iteratorId, bvhNodeId) {
        let node = this.getBvhNode(bvhNodeId);

        let lockedTiles = this.iteratorLockedTiles.get(iteratorId);
        if (lockedTiles.delete(bvhNodeId)) {
            node.lockedCounter--;
        }
        console.assert(lockedTiles && node.lockedCounter >= 0);
    }

    /**
     * Locks a tile corresponding to the given nodeId. While tiles are locked
     * they cannot be removed from the GPU memory.
     * The reason this is needed is, that the RenderScene keeps render batches across frames
     * while doing progressive rendering and we must not free the resources hold by one of these
     * cached batches. Only after the batch has been rendered or the render scene has been
     * reset can we free the resources.
     *
     * @param {number} iteratorId -- The id of the iterator
     * @param {number} bvhNodeId -- The id of the node in the BVH
     */
    lockTile(iteratorId, bvhNodeId) {
        let node = this.getBvhNode(bvhNodeId);
        node.lockedCounter++;

        let lockedTiles = this.iteratorLockedTiles.get(iteratorId);
        if (!lockedTiles) {
            lockedTiles = new Set();
            this.iteratorLockedTiles.set(iteratorId, lockedTiles);
        }
        lockedTiles.add(bvhNodeId);
    }

    /**
     * Increment the frame count
     */
    incrementFrameCount(iteratorId) {
        this.#frameCounts[iteratorId] = (this.#frameCounts[iteratorId] ?? 0) + 1;
    }

    /**
     * Resets the screen space errors in all nodes for the given iterator
     * @param {number} iteratorId - The ID of the iterator
     */
    resetScreenSpaceErrors(iteratorId) {
        // Reset the screen space errors for all nodes
        this.bvhNodes.forEach(node => { // forEach skips empty slots
            node.updateScreenSpaceError(iteratorId, -Infinity, 0);
            node.updateLastRendered(iteratorId, 0);
        });
    }

    /**
     * Obtains the ID for the given model iterator
     * @param {ModelIteratorBVH} iterator
     * @param {boolean} doNotCreate - Whether to create a new ID if it does not exist
     * @returns
     */
    getIteratorId(iterator, doNotCreate = false) {
        let ids = this.iteratorIdRegistry;

        // Do we already have an entry for the model?
        let index = ids.indexOf(iterator);
        if (index !== -1) {
            return index;
        }

        if (doNotCreate) {
            return undefined;
        }

        // If not, insert the model in the next free slot
        for (let i = 0; true; i++) {
                if (ids[i] === undefined) {
                        ids[i] = iterator;
                        return i;
                }
        }
    }

    /**
     * Assign a viewer to the OutOfCoreTileManager
     * @param {Viewer3DImpl} viewer - The viewer instance to use for this OutOfCoreTileManager
     */
    #addViewer(viewer) {
        this.#viewers.push(viewer);
        this.#renderer = viewer.glrenderer();
    }

    /**
     * Removes a viewer from the OutOfCoreTileManager
     * @param {Viewer3DImpl} viewer - The viewer instance to use for this OutOfCoreTileManager
     * @param {RenderModel} model - The model which is being removed
     */
    #removeViewer(viewer, model) {
        let viewerIndex = this.#viewers.indexOf(viewer);
        if (viewerIndex !== -1) {
            this.#viewers.splice(viewerIndex, 1);
        }

        const bvhIterator = model.getIterator();
        const iteratorId = this.iteratorIdRegistry.indexOf(bvhIterator);
        if (iteratorId !== -1) {
            this.releaseIterator(iteratorId);
        }
    }

    /**
     * Returns a viewer instance for this OutOfCoreTileManager. There might be multiple viewers
     * associated to this OutOfCoreTileManager if those instances share resources. In such a case,
     * we return the first viewer instance.
     *
     * @returns {Viewer3DImpl} - The viewer instance
     */
    getViewer() {
        return this.#viewers[0];
    }

    /**
     * Get the Renderer associated with this OutOfCoreTileManager
     * @returns {WebGLRenderer} - The renderer instance
     */
    getRenderer() {
        return this.#renderer;
    }

    /**
     * Returns the next batch of geometries to be requested from the server.
     * @returns {{hashes: Set<String>}|null, callback: Function} - Hashes to be requested and callback for finished requests or null if none needed
     */
    static getNextBVHNodeToPrefetchGeometryFor() {
        OutOfCoreTileManager.pendingNodes.sort(
            (a, b) => {
                // sort unconsolidated nodes to the back
                if (!a.model.getConsolidation()) return 1;
                if (!b.model.getConsolidation()) return -1;
                return -a.compare(b); }
        );

        while (OutOfCoreTileManager.pendingNodes.length > 0) {

            const node = OutOfCoreTileManager.pendingNodes.pop();

            const model = node.outOfCoreTileManager.model;
            const consolidation = model.getConsolidation();
            if (!consolidation) { // not yet consolidated
                OutOfCoreTileManager.pendingNodes.push(node);
                return null;
            }

            const fragList = model.getFragmentList();
            const bvhModelIterator = model.getIterator();
            const renderBatch = bvhModelIterator.getGeomScenes()[node.nodeId];
            const renderBatchIndices = renderBatch.getIndices();

            const missingHashes = new Set();
            let cannotConsolidate = false;

            for (let i = renderBatch.start; i < renderBatch.lastItem; i++) {

                const fragId = renderBatchIndices[i];

                // TODO If a fragment is set to MESH_NOTLOADED, its geometry will never be loaded.
                // So we shouldn't request this from the OtgResourceCache, and there is nothing to wait for,
                // but at the moment this also means we cannot consolidate the whole node.
                if (fragList.isNotLoaded(fragId)) {
                    cannotConsolidate = true;
                    continue;
                }

                const geomId = fragList.getGeometryId(fragId);
                if (geomId !== 0 && !fragList.geoms.hasGeometry(geomId)) {
                    missingHashes.add(model.loader.svf.getGeometryHash(geomId));
                }
                // materials are always requested immediately when loading the fraglist, but we still need to wait for them.
                if (!fragList.hasMaterial(fragId)) {
                    // The loader might already have the material, but it's not set on the fragment until it's activated.
                    // So we have to check pendingMaterials.
                    const materialId = model.loader.svf.fragments.materials[fragId];
                    const hash = model.loader.svf.getMaterialHash(materialId);
                    if (model.loader.pendingMaterials.has(hash)) {
                        missingHashes.add(hash);
                    }
                }
            }

            if (missingHashes.size === 0 && !cannotConsolidate) {
                node.outOfCoreTileManager.initializeNode(node.nodeId);
                continue;
            }

            let numMissingHashes = missingHashes.size;
            return {
                hashes: missingHashes,
                callback: (success) => {
                    if (!success) {
                        // A required geom or material failed to load. Don't try consolidating,
                        // because the tile processing code doesn't expect missing geom or materials.
                        cannotConsolidate = true;
                    } else if (--numMissingHashes === 0 && !cannotConsolidate) {
                        node.outOfCoreTileManager.initializeNode(node.nodeId);
                    }
                }
            };
        }
        return null;
    }

    /**
     * Adds a task that is not directly related to a specific node to be processed by the OutOfCoreTileManager
     * @param {Function} task
     */
    static addGlobalTask(task) {
        OutOfCoreTileManager.globalTasks.push(task);
    }

    /**
     * Adds a task that is run every tick and that is not cleared. Use for fast tasks only.
     * @param {Function} task
     */
    static addRecurringTask(task) {
        OutOfCoreTileManager.recurringTasks.push(task);
    }

    /**
     * Removes a recurring task.
     * @param {Function} task
     */
    static removeRecurringTask(task) {
        const index = OutOfCoreTileManager.recurringTasks.indexOf(task);
        if (index !== -1) {
            OutOfCoreTileManager.recurringTasks.splice(index, 1);
        }
    }

    /**
     * Run tasks until the deadline.
     * @param {Number} deadline - when to stop processing tasks.
     */
    static executeTasks(deadline) {

        for (const task of OutOfCoreTileManager.recurringTasks) {
            task(deadline);
        }

        // I don't really know how to best prioritize global tasks
        // compared to upload tasks yet. So for the moment, I just
        // have a heuristic, that if in the last frame we didn't
        // have any time to process upload tasks, we will skip
        // global tasks for this frame.
        if (!OutOfCoreTileManager.onlyGlobalTasksExecuted || OutOfCoreTileManager.#bvhNodesPendingQueue.length === 0) {
            while (OutOfCoreTileManager.globalTasks.length > 0) {
                let task = OutOfCoreTileManager.globalTasks[0];
                let completed = task(deadline);

                if (completed) {
                    OutOfCoreTileManager.globalTasks.shift();
                }

                if (performance.now() >= deadline) {
                    OutOfCoreTileManager.onlyGlobalTasksExecuted = true;
                    return;
                }
            }
        }
        OutOfCoreTileManager.onlyGlobalTasksExecuted = false;

        // Don't start processing events if models have been loaded, but not yet added to the viewer
        // The OutOfCoreTileManager for these models have not yet been fully initialized
        if (OutOfCoreTileManager.notYetAddedManagers.size > 0) {
            return;
        }

        OutOfCoreTileManager.#stats.uploadedThisFrame = 0;
        OutOfCoreTileManager.#stats.removedThisFrame = 0;

        // If there is a node, for which we are currently processing tasks, we want to keep it in the front of the queue,
        // because we want to finish it before we start processing other nodes
        let currentlyProcessedNode = undefined;
        if (OutOfCoreTileManager.#bvhNodesPendingQueue.length > 0 &&
                OutOfCoreTileManager.#bvhNodesPendingQueue[0].processedTasks.length > 0) {
            currentlyProcessedNode = OutOfCoreTileManager.#bvhNodesPendingQueue.shift();
        }

        // Sort nodes by screen space error in descending order to consolidate and upload most important ones first
        OutOfCoreTileManager.#bvhNodesPendingQueue.sort(
            (a, b) => {
                return a.compare(b);
            }
        );

        // If there was a node that is being processed, put it back in the front of the queue
        if (currentlyProcessedNode) {
            OutOfCoreTileManager.#bvhNodesPendingQueue.unshift(currentlyProcessedNode);
        }


        // Ensure nodes on gpu are sorted with ascending screen space errors to replace least important ones first
        OutOfCoreTileManager.#bvhNodesWorkingSetOnGpu.sort(
            (a, b) => {
                return -a.compare(b);
            }
        );

        while (OutOfCoreTileManager.#bvhNodesPendingQueue.length > 0) {
            const nextNode = OutOfCoreTileManager.#bvhNodesPendingQueue[0];

            let memoryLeft = OutOfCoreTileManager.#memoryLimit - OutOfCoreTileManager.#memoryConsumed;

            // The amount of memory left can become negative, if the memory limit is reduced and
            // not all memory could be freed instantly, because of locked nodes. In that case, we
            // free the memory here, without any reference to a comparison node, but we would
            if (memoryLeft < 0) {
                OutOfCoreTileManager.tryToFreeMemory(null, -memoryLeft);
                memoryLeft = OutOfCoreTileManager.#memoryLimit - OutOfCoreTileManager.#memoryConsumed;
            }
            let requiredMemory = nextNode.getRemainingMemoryCost();

            if (requiredMemory > memoryLeft) {
                this.#trackGPUOutOfCore(nextNode.model);
                // Check whether there is some other geometry we could free to be able to process this task
                if (!OutOfCoreTileManager.tryToFreeMemory(nextNode, requiredMemory)) {

                    // Check whether there are still tasks to be done that don't depend on memory resources
                    if (OutOfCoreTileManager.numResourceIndependentTasks > 0) {
                        for (const bvhNode of OutOfCoreTileManager.#bvhNodesPendingQueue) {
                            while (bvhNode.hasResourceIndependentTasks()) {
                                bvhNode.processNextResourceIndependentTask();
                                if (performance.now() >= deadline) {
                                    return;
                                }
                            }
                        }
                    }

                    break;
                }
            }

            let atLeastOneResourceDependentTask = false;
            while (nextNode.hasNextTask()) {
                if (!nextNode.nextTaskWillFit()) {
                    return;
                }
                const [memoryCost, task] = nextNode.processNextTask();

                if (!task?.isResourceIndependent) {
                    atLeastOneResourceDependentTask = true;
                }

                OutOfCoreTileManager.#memoryConsumed += memoryCost;

                if (!nextNode.hasNextTask()) {
                    OutOfCoreTileManager.#bvhNodesPendingQueue.shift();
                    // Only add the node to the working set if at least on task depends on resources and the node is not already in there.
                    // This is a workaround for nodes getting added to the bvhNodesPendingQueue multiple times in case they already have been processed before another task is added.
                    // Ideally, this shouldn't be necessary by design.
                    if (atLeastOneResourceDependentTask && OutOfCoreTileManager.#bvhNodesWorkingSetOnGpu.indexOf(nextNode) === -1) {
                        OutOfCoreTileManager.#stats.uploadedThisSecond++;
                        OutOfCoreTileManager.#stats.uploadedThisFrame++;
                        OutOfCoreTileManager.#bvhNodesWorkingSetOnGpu.push(nextNode);
                    }
                }
                if (performance.now() >= deadline) {
                    return;
                }
            }
        }
    }

    /**
     * Try to free the requested amount of memory by freeing nodes that have a lower screen space error
     *
     * @param {BvhNode|null} comparisonNode - The node for which we want to free memory and which we are
     *                                        comparing against to determine whether we can free memory.
     *                                        If null, we will free memory until the requested amount is freed
     *                                        not checking for any screen space error.
     * @param {number} requiredMemory - The amount of memory that needs to be freed
     * @returns {boolean} - Whether the requested amount of memory could be freed
     */
    static tryToFreeMemory(comparisonNode, requiredMemory) {
        let freeableMemory = 0;

        let i = 0;
        let memoryCanBeFreed = false;
        let scratchpad = {};
        for (; i < OutOfCoreTileManager.#bvhNodesWorkingSetOnGpu.length; i++) {
            let candidateNode = OutOfCoreTileManager.#bvhNodesWorkingSetOnGpu[i];

            if ((candidateNode.lockedCounter === 0) &&
                    (comparisonNode === null || comparisonNode.compare(candidateNode) < 0)) {
                freeableMemory += candidateNode.getFreeableMemory(scratchpad);
            } else {
                break;
            }

            if (freeableMemory > requiredMemory) {
                memoryCanBeFreed = true;
                break;
            }
        }
        if (!memoryCanBeFreed) {
            return false;
        }

        let totalMemoryFreed = 0;
        for (let j = 0; j <= i; j++) {
            let nodeToFree = OutOfCoreTileManager.#bvhNodesWorkingSetOnGpu.shift();
            let freedMemory = nodeToFree.freeMemory();
            totalMemoryFreed += freedMemory;
            OutOfCoreTileManager.#memoryConsumed -= freedMemory;
            OutOfCoreTileManager.#bvhNodesPendingQueue.push(nodeToFree);

            OutOfCoreTileManager.#stats.removedThisSecond++;
            OutOfCoreTileManager.#stats.removedThisFrame++;
        }
        console.assert(totalMemoryFreed === freeableMemory);

        return true;
    }

    /**
     * Sets the consolidation memory limit for the OutOfCoreTileManager.
     *
     * @param {number} value - The new consolidation memory limit value.
     */
    static setMemoryLimit(value) {
        let oldLimit = OutOfCoreTileManager.#memoryLimit;
        OutOfCoreTileManager.#memoryLimit = 0.9 * value;

            if (OutOfCoreTileManager.#memoryLimit < oldLimit) {
                this.tryToFreeMemory(null, oldLimit - OutOfCoreTileManager.#memoryLimit);
            }
    }

    /**
     * Returns the frame count for the specified iterator ID.
     * @param {string} iteratorId - The ID of the iterator.
     * @returns {number} The frame count for the specified iterator ID.
     */
    getFrameCount(iteratorId) {
        return this.#frameCounts[iteratorId] ?? 0;
    }

    /**
     * Get the statistics of the OutOfCoreTileManager.
     * @returns {Object} statistics of the OutOfCoreTileManager
     */
    static getStats() {
        if (!OutOfCoreTileManager.#statsUpdateTimer) {
            OutOfCoreTileManager.#statsUpdateTimer = setInterval(OutOfCoreTileManager.updateStats, 1000);
        }

        return {
            consolidationMemoryConsumed: OutOfCoreTileManager.#memoryConsumed,
            consolidationMemoryLimit: OutOfCoreTileManager.#memoryLimit,
            tilesInQueue: OutOfCoreTileManager.#bvhNodesPendingQueue.length,
            tilesOnGpu: OutOfCoreTileManager.#bvhNodesWorkingSetOnGpu.length,
            uploadedLastSecond: OutOfCoreTileManager.#stats.uploadedLastSecond,
            removedLastSecond: OutOfCoreTileManager.#stats.removedLastSecond,
            uploadedThisFrame: OutOfCoreTileManager.#stats.uploadedThisFrame,
            removedThisFrame: OutOfCoreTileManager.#stats.removedThisFrame,
        };
    }

    static updateStats() {
        OutOfCoreTileManager.#stats.uploadedLastSecond = OutOfCoreTileManager.#stats.uploadedThisSecond;
        OutOfCoreTileManager.#stats.removedLastSecond = OutOfCoreTileManager.#stats.removedThisSecond;
        OutOfCoreTileManager.#stats.uploadedThisSecond = 0;
        OutOfCoreTileManager.#stats.removedThisSecond = 0;
    }

    /**
     * Register a new viewer instance with the OutOfCoreTileManager
     * @param {Viewer3DImpl} viewer
     */
    static registerViewer(viewer) {
        viewer.api.addEventListener(EventTypes.MODEL_ADDED_EVENT, OutOfCoreTileManager.#onModelAdded.bind(undefined, viewer));
        viewer.api.addEventListener(EventTypes.MODEL_REMOVED_EVENT, OutOfCoreTileManager.#onModelRemoved.bind(undefined, viewer));
        viewer.api.addEventListener(EventTypes.VIEWER_VISIBILITY_CHANGED, OutOfCoreTileManager.#onVisibilityChanged.bind(undefined, viewer));
        viewer.api.addEventListener(MODEL_CONSOLIDATION_ENABLED, OutOfCoreTileManager.#onModelConsolidated.bind(undefined, viewer));
    }

    /**
     * Register a new viewer instance with the OutOfCoreTileManager
     * @param {Viewer3DImpl} viewer
     */
    static unregisterViewer(viewer) {
        viewer.api.removeEventListener(EventTypes.MODEL_ADDED_EVENT, OutOfCoreTileManager.#onModelAdded.bind(undefined, viewer));
        viewer.api.removeEventListener(EventTypes.MODEL_REMOVED_EVENT, OutOfCoreTileManager.#onModelRemoved.bind(undefined, viewer));
        viewer.api.removeEventListener(EventTypes.VIEWER_VISIBILITY_CHANGED, OutOfCoreTileManager.#onVisibilityChanged.bind(undefined, viewer));
        viewer.api.removeEventListener(MODEL_CONSOLIDATION_ENABLED, OutOfCoreTileManager.#onModelConsolidated.bind(undefined, viewer));
    }

    /**
     * When a model gets consolidated, we need to retrigger otg cache processing and initialize the nodes
     * @param {Viewer3DImpl} viewer
     * @param {{visible: boolean}} event        - The event object
     */
    static #onModelConsolidated(viewer, event) {
        viewer.geomCache().startProcessing();
        event.model.getIterator()._initializeNodes();
    }

    /**
     * The visibility for a viewer has changed
     * @param {Viewer3DImpl} viewer
     * @param {{visible: boolean}} event        - The event object
     */
    static #onVisibilityChanged(viewer, event) {
        if (!event.visible) {
            // If the viewer has been hidden, we have to reset the error for
            // all nodes, because the screen space error is not valid anymore
            for (let model of viewer.api.getAllModels()) {
                let [manager, iteratorID] = OutOfCoreTileManager.getManagerForIterator(model.getIterator(), model, true);
                if (manager && iteratorID) {
                    manager.resetScreenSpaceErrors(iteratorID);
                }
            }
        }
    }

    /**
     * A model has been added to a viewer
     * @param {Viewer3DImpl} viewer               - The viewer instance
     * @param {{model: RenderModel}} event        - The event object
     */
    static #onModelAdded(viewer, event) {
        let outOfCoreTileManager;

        // First check, whether we already have registered a model with the same leechViewerKey
        // (i.e. whether this model shares resources with a different model). In that case, we
        // we will use the already registered OutOfCoreTileManager for the referenced model
        OutOfCoreTileManager._updateLeechViewerKeysInModelRegistry();
        if (event.model.leechViewerKey !== undefined) {
            outOfCoreTileManager = OutOfCoreTileManager.modelManagerRegistry.get(event.model.leechViewerKey);
        }

        // Check, whether the model has already directly been registered with the out-of-core manager
        if (!outOfCoreTileManager) {
            outOfCoreTileManager = OutOfCoreTileManager.modelManagerRegistry.get(event.model);
        }
        OutOfCoreTileManager.notYetAddedManagers.delete(outOfCoreTileManager);

        // If we did not find any manager, we create a new one
        if (!outOfCoreTileManager) {
            outOfCoreTileManager = new OutOfCoreTileManager(event.model);

            // If this model is using a LeechViewer, we also store a reference with the leechViewerKey
            if (event.model.leechViewerKey !== undefined) {
                OutOfCoreTileManager.modelManagerRegistry.set(event.model.leechViewerKey, outOfCoreTileManager);
            }
        }
        OutOfCoreTileManager.modelManagerRegistry.set(event.model, outOfCoreTileManager);

        OutOfCoreTileManager.#t1_GPUUpload = undefined;

        // Assign the the viewer to the out-of-core tile manager
        outOfCoreTileManager.#addViewer(viewer);
    }

    /**
     * A model has been removed from a viewer
     * @param {Viewer3DImpl} viewer               - The viewer instance
     * @param {{model: RenderModel}} event        - The event object
     */
    static #onModelRemoved(viewer, event) {
        let outOfCoreTileManager = OutOfCoreTileManager.modelManagerRegistry.get(event.model);
        if (outOfCoreTileManager) {
            outOfCoreTileManager.#removeViewer(viewer, event.model);
        }

        OutOfCoreTileManager.modelManagerRegistry.delete(event.model);

        if (event.model.leechViewerKey !== undefined) {
            let usageCount = Array.from(OutOfCoreTileManager.modelManagerRegistry.keys()).filter(x => x.leechViewerKey === event.model.leechViewerKey).length;

            // If we no longer have any reference to the leechViewerKey, we can also remove that entry
            if (usageCount === 0) {
                OutOfCoreTileManager.modelManagerRegistry.delete(event.model.leechViewerKey);
            }
        }
    }

    /**
     * Gets the OutOfCoreTileManager and the iteratorId for an iterator
     * @param {ModelIteratorBVH} iterator - The BVH iterator
     * @param {RenderModel} model - The model the BVH iterator is responsible for
     * @param {boolean} doNotCreate - Whether to create a new manager if none is found
     * @return {[OutOfCoreTileManager, number]}
     */
    static getManagerForIterator(iterator, model, doNotCreate = false) {
        OutOfCoreTileManager._updateLeechViewerKeysInModelRegistry();
        let  manager = OutOfCoreTileManager.modelManagerRegistry.get(model.leechViewerKey) ??
                                        OutOfCoreTileManager.modelManagerRegistry.get(model);

        if (!manager) {
            if (doNotCreate) {
                return [undefined, 0];
            } else {
                // Create a new manager if none yet exists (this can happen if the
                // BVH is created before the model is added to the viewer)
                manager = new OutOfCoreTileManager(model);

                // If this model is using a LeechViewer, we also store a reference with the leechViewerKey
                if (model.leechViewerKey !== undefined) {
                    OutOfCoreTileManager.modelManagerRegistry.set(model.leechViewerKey, manager);
                }
                OutOfCoreTileManager.modelManagerRegistry.set(model, manager);
                OutOfCoreTileManager.notYetAddedManagers.add(manager);
            }
        }

        let id = manager.getIteratorId(iterator, doNotCreate);

        return [manager, id];
    }

    /**
     * Updates the leechViewerKeys in the model registry
     *
     * It can happen that models are added to the registry, before their leechViewerKey is set. In that case, we
     * would not have them under the correct key in the registry. This function updates the registry to ensure that
     * all models are registered under the correct key.
     */
    static _updateLeechViewerKeysInModelRegistry() {
        for (let [existingModel, outOfCoreTileManager] of OutOfCoreTileManager.modelManagerRegistry.entries()) {
            if (existingModel.leechViewerKey && !OutOfCoreTileManager.modelManagerRegistry.has(existingModel.leechViewerKey)) {
                OutOfCoreTileManager.modelManagerRegistry.set(existingModel.leechViewerKey, outOfCoreTileManager);
            }
        }
    }

        /**
         * Free all GPU Ressources in the case of a context loss.
         */
        static resetAfterContextLoss() {
            for (let manager of OutOfCoreTileManager.modelManagerRegistry.values()) {
                manager.freeAllNodes();
            }
        }

    /**
     * Add analytics for GPU out-of-core, including:
     * - Time to reach the GPU memory limit the first time, if it was reached at all
     *
     * Note the OutOfCoreTileManager operates on multiple models, so there is no specific URL attached to the event.
     *
     * @param {RenderModel} [model]
     */
    static #trackGPUOutOfCore(model) {
        // only track the first time
        if (OutOfCoreTileManager.#t1_GPUUpload) {
            return;
        }

        this.#t1_GPUUpload = performance.now();
        const stats = OutOfCoreTileManager.getStats();
        const properties = {
            memory_limit_time: this.#t1_GPUUpload - this.#t0_ModelCreation,
            consolidation_memory_consumed: stats.consolidationMemoryConsumed,
            consolidation_memory_limit: stats.consolidationMemoryLimit,
            tiles_in_queue: stats.tilesInQueue,
            tiles_on_gpu: stats.tilesOnGpu,
            urn: model?.getData()?.urn ?? 'NOT_SET',
        };
        analytics.track('viewer.model.gpu_out_of_core', properties);
    }

    static setModelLoadStartedTimestamp() {
        this.#t0_ModelCreation = performance.now();
    }
}

// Ressource manager is currently not yet merged in dev
/*
OutOfCoreTileManager.setMemoryLimit(ResourceManager.getHardwareLimits().GPU_MEMORY_LIMIT);

// Register listener for hardware level changes to update consolidation memory limit
ResourceManager.addEventListener(et.HARDWARE_LEVEL_CHANGED, function() {
    OutOfCoreTileManager.setMemoryLimit(ResourceManager.getHardwareLimits().GPU_MEMORY_LIMIT);
});
*/
